define("ember-svg-jar/inlined/tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<clipPath id=\"a\"><path d=\"M0 0h201.247v201.247H0z\"/></clipPath><path d=\"M0 0h201.247v201.247H0z\" fill=\"none\"/><g clip-path=\"url(#a)\"><path d=\"M100.665.085c55.535 0 100.623 45.088 100.623 100.624 0 55.535-45.088 100.623-100.623 100.623C45.129 201.332.041 156.244.041 100.709.041 45.173 45.129.085 100.665.085zm-16.741 163.78c0 9 7.4 16.4 16.4 16.4s16.6-7.4 16.6-16.4-7.6-16.6-16.6-16.6-16.4 7.6-16.4 16.6zm8.4-40h16.2c2.6 0 4-1.8 4-4.2v-6.6c0-12 30.4-19 30.4-43.2 0-19.6-17.8-33.6-38.8-33.6-25.6 0-39.2 17.6-39.2 17.6-1.4 1.4-1.6 3.8 0 5.2l9.4 9.2c1.4 1.2 2.8 1.2 4.4.2 4-2.6 9.4-10.4 21.6-10.4 10 0 16 6.2 16 13.4 0 14-27.8 21.2-27.8 40.2v8.4c0 2.2 1.8 3.8 3.8 3.8z\"/></g>",
    "attrs": {
      "clip-rule": "evenodd",
      "fill-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414",
      "viewBox": "0 0 202 202",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});