define("ember-click-outside-modifier/modifiers/click-outside", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HAS_WINDOWS = typeof window !== 'undefined';
  const HAS_NAVIGATOR = typeof navigator !== 'undefined';
  const IS_TOUCH = HAS_WINDOWS && ('ontouchstart' in window || HAS_NAVIGATOR && navigator.msMaxTouchPoints > 0);
  const EVENTS = IS_TOUCH ? ['touchstart'] : ['click'];

  function getEventNames(_ref) {
    let {
      event,
      events
    } = _ref;

    if (events) {
      return events;
    }

    if (event) {
      return [event];
    }

    return EVENTS;
  }

  var _default = (0, _emberModifier.modifier)(function clickOutside(element) {
    let [handlerValue, useCapture] = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [undefined, false];
    let hashParams = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    const refEvent = new Event('clickReference');
    const events = getEventNames(hashParams);
    const isFunction = typeof handlerValue === 'function';

    if (!isFunction) {
      throw new Error('{{click-outside}}: Handler value must be a function.');
    }

    const handlers = [];
    events.forEach(eventName => {
      const handler = event => {
        if (refEvent.timeStamp > event.timeStamp) {
          return;
        }

        const isClickOutside = event.target !== element && !element.contains(event.target);

        if (!isClickOutside) {
          return;
        }

        handlerValue(event);
      };

      handlers.push([eventName, handler]);
      document.documentElement.addEventListener(eventName, handler, useCapture);
    });
    return () => {
      handlers.forEach(_ref2 => {
        let [eventName, handler] = _ref2;
        document.documentElement.removeEventListener(eventName, handler, useCapture);
      });
    };
  });

  _exports.default = _default;
});