define("ember-svg-jar/inlined/false", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15.6.1C7.1.1.1 7.1.1 15.6s7 15.5 15.5 15.5 15.5-7 15.5-15.5S24.2.1 15.6.1z\" fill=\"red\"/><path d=\"M24.8 6.4c-.4-.4-1-.4-1.6 0l-7.8 7.8-7.7-7.8c-.4-.4-1-.4-1.5 0-.4.4-.4 1 0 1.6l7.8 7.8-7.8 7.8c-.4.4-.4 1 0 1.6.5 0 .8.1 1 .1.3 0 .5-.1.8-.3l7.8-7.8 7.8 7.8c.3.3.5.3.8.3s.5-.1.8-.3c.4-.4.4-1 0-1.6l-7.8-7.8L25 7.8c.3-.2.3-1-.2-1.4z\" fill=\"#fff\" stroke=\"#fff\" stroke-miterlimit=\"10\"/>",
    "attrs": {
      "viewBox": "0 0 31.3 31.3",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});