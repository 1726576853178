define("ember-svg-jar/inlined/focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"22.1\" cy=\"16\" transform=\"rotate(-23.828 22.071 16.002)\" r=\"13.2\"/><path d=\"M5.8 37.2l8.6-10.5M10.2 21.7l6.3-6.2 3.5 3.9 7.2-7.1\"/><path d=\"M22.7 12.1h4.7v4.7\"/>",
    "attrs": {
      "viewBox": "0 0 41 40",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});