define("ember-svg-jar/inlined/binoculars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M26 6V0h-8v6h-2v6h-4V6h-2V0H2v6H0v26h12V22h4v10h12V6zM4 2h4v4H4zm6 28H2v-4h8zm0-6H2V8h8zm6-4h-4v-6h4zm4-18h4v4h-4zm6 28h-8v-4h8zm0-6h-8V8h8z\" fill=\"#3c4042\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "viewBox": "0 0 28 32",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});