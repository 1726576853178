define("ember-svg-jar/inlined/target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M29.4 6c2.1 1.3 3.8 3.1 5.1 5.2M6.4 11.2C7.8 9 9.7 7.1 12 5.8M11.9 34.2c-2.2-1.3-4.1-3.2-5.5-5.4M34.5 28.8c-1.3 2.1-3 3.8-5.1 5.1M20.5 3.4v7.5M20.5 29.1v7.5M37.1 20h-7.5M11.4 20H3.9M12.3 11.8h16.4v16.4H12.3z\"/><path d=\"M12.3 24.4l5.2-5.3 3.3 3.3 8-8\"/>",
    "attrs": {
      "viewBox": "0 0 41 40",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});