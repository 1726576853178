define("ember-svg-jar/inlined/back", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M104.6 105h215.5c14.8 0 26.7 8.8 30.7 22.5 4.1 14.1-1.1 29.6-13.5 36.6-5.3 2.9-11.9 4.7-17.9 4.7-69.3.3-138.6.2-208 .2h-6.7c5.5 5.9 10.3 11 15.1 16.1l32.1 33.6c9.2 9.7 11.9 21.1 7.9 33.7-3.7 11.5-11.7 18.9-23.8 21.1-10.7 2-19.8-1.7-27.1-9.4-33.3-35-66.6-69.9-99.8-105-12.4-13.1-11.9-32.2.7-45.4 32.6-34 65.2-68.1 97.7-102.2C120.5-2.3 138-3.9 151 7.7c13.1 11.7 14.4 32.7 2.4 45.9-12.2 13.4-25 26.3-37.5 39.5-3.5 3.6-6.9 7.2-11.3 11.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 352.1 274"
    }
  };
  _exports.default = _default;
});