define("ember-svg-jar/inlined/correct", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15.6.1C7.1.1.1 7.1.1 15.6s7 15.5 15.5 15.5 15.5-7 15.5-15.5S24.2.1 15.6.1zm0 29.3C8 29.4 1.9 23.2 1.9 15.6S8 1.9 15.6 1.9 29.4 8 29.4 15.6s-6.2 13.8-13.8 13.8z\"/><path d=\"M23.4 10.8l-9.8 9.8L9.1 16c-.3-.3-.8-.3-1.1 0s-.3.8 0 1.1l5 5.1c.1.1.3.2.5.2.1 0 .2 0 .3-.1.1 0 .2-.1.3-.2l10.3-10.3c.3-.3.3-.8 0-1.1s-.7-.2-1 .1z\"/>",
    "attrs": {
      "viewBox": "0 0 31.3 31.3",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});