define("ember-svg-jar/inlined/status-inprogress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.46 9.37c-.53.53-1.39.53-1.92 0L4.31 6.13c-.53-.53-.53-1.39 0-1.91.53-.53 1.39-.53 1.92 0l3.23 3.23c.53.53.53 1.39 0 1.92z\"/><path d=\"M24.55 24.45c-.53.53-1.39.53-1.92 0l-3.23-3.23a1.358 1.358 0 011.92-1.92l3.23 3.23c.52.54.52 1.39 0 1.92z\" opacity=\".4\"/><path d=\"M12.61 7.55c-.72.19-1.47-.24-1.66-.96L9.76 2.17c-.19-.72.24-1.46.96-1.66.72-.19 1.47.24 1.66.96l1.18 4.42c.2.72-.23 1.46-.95 1.66z\" opacity=\".9\"/><path d=\"M18.13 28.16c-.72.19-1.47-.24-1.66-.96l-1.18-4.42c-.19-.72.23-1.47.96-1.66.72-.19 1.47.24 1.66.96l1.18 4.42c.19.72-.24 1.46-.96 1.66z\" opacity=\".3\"/><path d=\"M16.25 7.55c-.72-.19-1.15-.94-.96-1.66l1.18-4.42c.19-.72.94-1.15 1.66-.96s1.15.94.96 1.66L17.9 6.59a1.35 1.35 0 01-1.65.96z\" opacity=\".85\"/><path d=\"M10.72 28.16c-.72-.2-1.15-.94-.96-1.66l1.18-4.42c.19-.72.94-1.15 1.66-.96s1.15.94.96 1.66l-1.18 4.42c-.19.72-.93 1.15-1.66.96z\" opacity=\".2\"/><path d=\"M19.4 9.37c-.53-.53-.53-1.39 0-1.92l3.23-3.23a1.358 1.358 0 011.92 1.92l-3.23 3.23c-.54.52-1.4.53-1.92 0z\" opacity=\".8\"/><path d=\"M4.31 24.45c-.53-.53-.53-1.39 0-1.92l3.23-3.23a1.358 1.358 0 011.92 1.92l-3.23 3.23c-.53.53-1.39.53-1.92 0z\" opacity=\".15\"/><path d=\"M21.21 12.52c-.19-.72.24-1.47.96-1.66l4.42-1.18c.72-.19 1.47.24 1.66.96s-.24 1.47-.96 1.66l-4.42 1.18c-.72.19-1.46-.24-1.66-.96z\" opacity=\".7\"/><path d=\"M.6 18.04c-.19-.72.24-1.47.96-1.66l4.42-1.18c.72-.2 1.46.23 1.66.95.19.72-.24 1.47-.96 1.66L2.26 19c-.72.19-1.46-.24-1.66-.96z\" opacity=\".1\"/><path d=\"M21.21 16.15c.19-.72.94-1.15 1.66-.96l4.42 1.18c.72.19 1.15.94.96 1.66s-.94 1.15-1.66.96l-4.42-1.18c-.72-.19-1.15-.93-.96-1.66z\" opacity=\".6\"/><path d=\"M.6 10.63c.2-.72.94-1.15 1.66-.96l4.42 1.18c.72.19 1.15.94.96 1.66s-.94 1.15-1.66.96l-4.42-1.18C.84 12.1.41 11.35.6 10.63z\" opacity=\".05\"/>",
    "attrs": {
      "viewBox": "0 0 28.8 28.8",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});