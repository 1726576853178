define("ember-svg-jar/inlined/newspaper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke=\"#000\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.125\"><path d=\"M17.438 4.5v10.688a1.687 1.687 0 11-3.376 0V2.25a1.125 1.125 0 00-1.125-1.125H1.687A1.125 1.125 0 00.562 2.25v12.938a1.687 1.687 0 001.688 1.687h13.5M3.375 11.25h7.875M3.375 13.5h4.5\"/><path d=\"M3.375 3.938h7.875v4.5H3.375z\"/></g>",
    "attrs": {
      "fill": "none",
      "height": "18",
      "viewBox": "0 0 18 18",
      "width": "18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});