define("ember-svg-jar/inlined/external-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"none\" d=\"M0 0h768v768H0z\"/><path d=\"M640 640H128V129.906L256 128V0H0v768h768V448H640v192zM384 0l128 128-192 192 128 128 192-192 128 128V0H384z\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "viewBox": "0 0 768 768",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "2"
    }
  };
  _exports.default = _default;
});