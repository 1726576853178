define("ember-svg-jar/inlined/folder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M26 4H16c0-2.2-1.8-4-4-4H4C1.8 0 0 1.8 0 4v24c0 2.2 1.8 4 4 4h22c2.2 0 4-1.8 4-4V8c0-2.2-1.8-4-4-4zm2 10v14c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V12h26zM2 10V4c0-1.1.9-2 2-2h8c1.1 0 2 .9 2 2v2h12c1.1 0 2 .9 2 2v2z\" fill=\"#3c4042\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "viewBox": "0 0 30 32",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});