define("ember-svg-jar/inlined/graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M30 0H2C.9 0 0 .9 0 2v28c0 1.1.9 2 2 2h28c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zM2 3c0-.6.4-1 1-1h26c.6 0 1 .4 1 1v1.5L17.2 17.3l-7.6-5.2-.1-.1-.5.5-7 6.3zm27 27H3c-.6 0-1-.4-1-1v-7.5l7.7-6.9 7.7 5.2.1.1 1.5-1.5 11-11V29c0 .6-.4 1-1 1z\" fill=\"currentColor\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});