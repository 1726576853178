define("ember-svg-jar/inlined/lifebuoy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke=\"#fff\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.875\"><path d=\"M13.5 21a7.5 7.5 0 1015 0 7.5 7.5 0 00-15 0zM31.238 37.602l-9.18-9.18M28.422 22.06l9.18 9.178M4.398 31.238l9.18-9.18M19.942 28.424l-9.18 9.178M10.762 4.4l9.18 9.178M13.578 19.942l-9.18-9.18M37.602 10.762l-9.18 9.18M22.058 13.578l9.18-9.178\"/><path d=\"M1.5 21a19.5 19.5 0 1039 0 19.5 19.5 0 00-39 0z\"/></g>",
    "attrs": {
      "fill": "none",
      "height": "42",
      "viewBox": "0 0 42 42",
      "width": "42",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});