define("ember-svg-jar/inlined/processes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M111 176v49H8v-49zm8-8H0v65h119zM184 8v49H81V8zm8-8H73v65h119z\"/><path d=\"M128 42h8v77h-8z\"/><path d=\"M56 115h153v8H56z\"/><path d=\"M56 118h8v52h-8zM201 118h8v59h-8z\"/><path d=\"M256 176v49H153v-49zm8-8H145v65h119z\"/>",
    "attrs": {
      "clip-rule": "evenodd",
      "fill-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414",
      "viewBox": "0 0 264 233",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});