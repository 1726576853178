define("ember-light-table/components/lt-scrollable", ["exports", "@ember/component", "ember-light-table/templates/components/lt-scrollable"], function (_exports, _component, _ltScrollable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _ltScrollable.default,
    tagName: '',
    vertical: true,
    horizontal: false
  });

  _exports.default = _default;
});