define("ember-svg-jar/inlined/search--light-gray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M37.8 35c.8.8.8 2 0 2.8s-2 .8-2.8 0L24.1 26.9c-2.5 2-5.7 3.1-9.1 3.1-8.3 0-15-6.7-15-15S6.7 0 15 0s15 6.7 15 15c0 3.4-1.1 6.6-3.1 9.1zM15 26c6.1 0 11-4.9 11-11S21.1 4 15 4 4 8.9 4 15s4.9 11 11 11z\" fill=\"#a6aab0\"/>",
    "attrs": {
      "viewBox": "0 0 38.4 38.4",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});