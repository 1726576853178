define("ember-svg-jar/inlined/connected-insight-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st0{fill:#007e71}</style><path class=\"st0\" d=\"M16.6 32.8L0 43.8l18.6-6.6\"/><path fill=\"#ff9f7d\" d=\"M34.1 33.9L8.3 14.3l13.3 29.5z\"/><path fill=\"#159e90\" d=\"M25.2 27.2l19.3 15.3L70.6 0z\"/><path class=\"st0\" d=\"M18.6 37.2l-2-4.4\"/>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 70.6 43.8",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});