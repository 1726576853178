define("ember-light-table/templates/components/columns/base", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "FVWPOEwC",
    "block": "[[[41,[33,1,[\"component\"]],[[[1,\"  \"],[46,[33,1,[\"component\"]],null,[[\"column\",\"table\",\"tableActions\",\"extra\",\"sortIcons\"],[[33,1],[33,3],[33,4],[33,5],[33,6]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,7],[[33,6,[\"iconComponent\"]],[33,8]],null],[[[1,\"    \"],[46,[33,6,[\"iconComponent\"]],null,[[\"sortIcons\",\"sortIconProperty\"],[[33,6],[33,8]]],null],[1,\"\\n\"]],[]],[[[41,[33,8],[[[1,\"    \"],[10,\"i\"],[15,0,[29,[\"lt-sort-icon \",[28,[37,9],[[33,6],[33,8]],null]]]],[12],[13],[1,\"\\n  \"]],[]],null]],[]]],[1,\"  \"],[1,[34,10]],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[33,11],[[[1,\"  \"],[1,[28,[35,12],null,[[\"column\",\"table\",\"resizeOnDrag\",\"isResizing\",\"onColumnResized\"],[[33,1],[33,3],[33,13],[28,[37,14],[[33,15]],null],[28,[37,16],[[30,0],[33,17],[33,1]],null]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"column\",\"component\",\"table\",\"tableActions\",\"extra\",\"sortIcons\",\"and\",\"sortIconProperty\",\"get\",\"label\",\"isResizable\",\"lt-column-resizer\",\"resizeOnDrag\",\"mut\",\"isResizing\",\"action\",\"onColumnResized\"]]",
    "moduleName": "ember-light-table/templates/components/columns/base.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});