define("ember-svg-jar/inlined/powerbi-dashboards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<clipPath id=\"a\"><path d=\"M0 0h266v221H0z\"/></clipPath><path d=\"M0 0h266v221H0z\" fill=\"none\"/><g clip-path=\"url(#a)\" fill=\"#549f8b\" fill-rule=\"nonzero\"><path d=\"M266.5 221.5H.5V.5h266zm-259-7h252V7.5H7.5z\"/><path d=\"M84.5 198.5h-56v-99h56zm-49-7h42v-85h-42zM162.5 198.5h-56v-159h56zm-49-7h42v-145h-42zM239.5 198.5h-56v-115h56zm-49-7h42v-101h-42z\"/></g>",
    "attrs": {
      "clip-rule": "evenodd",
      "fill-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414",
      "viewBox": "0 0 266 221",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});