define("ember-svg-jar/inlined/pencil", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M131.2 406.3l-89.6-89.6L290.3 68c29.7 29.7 59.8 59.8 89.7 89.6-82.6 82.5-165.7 165.6-248.8 248.7zM392.1 144.5l-90.7-90.7c1.6-1 3.8-1.9 5.3-3.4 13.1-13 26.4-25.9 39.2-39.2 12.1-12.6 33.1-17 48.9-.1 14 15 28.9 29 43.3 43.6 12.1 12.3 14 29.8 2.4 42.3-15.3 16.5-32.2 31.7-48.4 47.5zM31.9 332.4l83.4 83.4c-.7.2-1.8.8-2.9 1.1-19.8 5.2-39.6 10.3-59.4 15.5-3.1.8-5.2.3-7.4-2.1-9.4-9.7-19.1-19.1-28.5-28.8-1.3-1.4-2.3-4.2-1.8-6 5.1-20.7 10.6-41.3 15.9-61.9.2-.5.6-1 .7-1.2zM9.1 416.4c7.6 7.6 14.7 14.7 22.3 22.2-10.3 3-20.5 6-31.4 9.1 3.2-10.8 6.1-20.9 9.1-31.3z\"/>",
    "attrs": {
      "viewBox": "0 0 448.3 447.8",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});