define("ember-svg-jar/inlined/caret-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6.3 0L0 8.2h12.7z\" fill=\"#838584\"/>",
    "attrs": {
      "viewBox": "0 0 12.7 8.2",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});