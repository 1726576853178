define("ember-svg-jar/inlined/caret-up-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#838584\"><path d=\"M6.3 0L0 8.2h12.7zM6.3 22.1l6.4-8.2H0z\"/></g>",
    "attrs": {
      "viewBox": "0 0 12.7 22.1",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});