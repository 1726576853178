define("ember-svg-jar/inlined/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M30.7 4.5L28 1.8c-2-2-5.4-2.1-7.4-.1l-.7.7-2.2 2.2c-.4.4-.4 1.1 0 1.5.2.2.5.3.7.3.3 0 .5-.1.7-.3l2.2-2.2.7-.7c1.2-1.2 3.3-1.2 4.6.1L29.3 6c1.2 1.2 1.3 3.3.1 4.5L22.8 17l-2.6 2.6c-1.2 1.2-3.7 1.4-4.8.2l-1.2-1.2-.1-.1-.7-.7c-.4-.4-1.1-.4-1.5 0s-.4 1.1 0 1.5l.8.8 1.2 1.2.1.1c.1.1.1.1.2.1 1.9 1.6 5 1.5 7-.2.1-.1.1-.2.2-.2l.1-.1 9.1-9.1c2.2-2 2.1-5.4.1-7.4zM13.4 26.4l-2.2 2.2-.7.7c-1.2 1.2-3.2 1.2-4.4 0l-2.9-2.9C2 25.2 2 23.2 3.2 22l9.4-9.4c1.2-1.2 3.2-1.2 4.4 0l1.5 1.5.7.7c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1.1 0-1.5l-.7-.7-1.5-1.5c-2-2-5.3-2-7.3 0l-9.4 9.4c-2 2-2 5.3 0 7.3l2.9 2.9c2 2 5.3 2 7.3 0l.7-.7 2.2-2.2c.4-.4.4-1.1 0-1.5-.4-.3-1-.3-1.4.1z\" fill=\"currentColor\" fill-rule=\"evenodd\"/><path d=\"M30.7 4.5L28 1.8c-2-2-5.4-2.1-7.4-.1l-.7.7-2.2 2.2c-.4.4-.4 1.1 0 1.5.2.2.5.3.7.3.3 0 .5-.1.7-.3l2.2-2.2.7-.7c1.2-1.2 3.3-1.2 4.6.1L29.3 6c1.2 1.2 1.3 3.3.1 4.5L22.8 17l-2.6 2.6c-1.2 1.2-3.7 1.4-4.8.2l-1.2-1.2-.1-.1-.7-.7c-.4-.4-1.1-.4-1.5 0s-.4 1.1 0 1.5l.8.8 1.2 1.2.1.1c.1.1.1.1.2.1 1.9 1.6 5 1.5 7-.2.1-.1.1-.2.2-.2l.1-.1 9.1-9.1c2.2-2 2.1-5.4.1-7.4zM13.4 26.4l-2.2 2.2-.7.7c-1.2 1.2-3.2 1.2-4.4 0l-2.9-2.9C2 25.2 2 23.2 3.2 22l9.4-9.4c1.2-1.2 3.2-1.2 4.4 0l1.5 1.5.7.7c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1.1 0-1.5l-.7-.7-1.5-1.5c-2-2-5.3-2-7.3 0l-9.4 9.4c-2 2-2 5.3 0 7.3l2.9 2.9c2 2 5.3 2 7.3 0l.7-.7 2.2-2.2c.4-.4.4-1.1 0-1.5-.4-.3-1-.3-1.4.1z\" fill=\"none\" stroke=\"currentColor\" stroke-miterlimit=\"10\" stroke-width=\".5\"/>",
    "attrs": {
      "viewBox": "0 0 32.5 32.5",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});