define("ember-svg-jar/inlined/filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M9.8 8.4H8.2V2.8C8.2 1.2 7 0 5.4 0S2.7 1.2 2.7 2.8v5.6H1.1C.5 8.4 0 8.9 0 9.5v.8c0 .6.5 1.1 1.1 1.1H9.9c.6 0 1.1-.5 1.1-1.1v-.8c-.1-.6-.6-1.1-1.2-1.1zM2.7 30.4c0 1.5 1.2 2.8 2.8 2.8s2.8-1.2 2.8-2.8V14.6H2.7zM22.4 20.5h-1.6V2.8C20.8 1.2 19.5 0 18 0s-2.8 1.2-2.8 2.8v17.7h-1.6c-.6 0-1.1.5-1.1 1.1v.8c0 .6.5 1.1 1.1 1.1H22.4c.6 0 1.1-.5 1.1-1.1v-.8c-.1-.6-.5-1.1-1.1-1.1zM15.2 30.4c0 1.5 1.2 2.8 2.8 2.8 1.5 0 2.8-1.2 2.8-2.8v-3.7h-5.6zM34.9 11h-1.6V2.8C33.3 1.3 32 0 30.5 0s-2.8 1.2-2.8 2.8V11h-1.6c-.6 0-1.1.5-1.1 1.1v.8c0 .6.5 1.1 1.1 1.1h8.7c.6 0 1.1-.5 1.1-1.1v-.8c.1-.6-.4-1.1-1-1.1zM27.8 30.4c0 1.5 1.2 2.8 2.8 2.8 1.5 0 2.8-1.2 2.8-2.8V17.1h-5.6z\"/>",
    "attrs": {
      "viewBox": "0 0 36 33.2",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});