define("ember-svg-jar/inlined/status-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.4.3C6.61.3.3 6.61.3 14.4s6.31 14.1 14.1 14.1 14.1-6.31 14.1-14.1S22.19.3 14.4.3zm8.04 11.22l-8.64 8.64a2.048 2.048 0 01-2.78.11L6.47 16.4c-.86-.73-.97-2.02-.23-2.88.73-.86 2.03-.97 2.89-.23l3.11 2.64 7.31-7.31c.8-.8 2.09-.8 2.89 0 .8.81.8 2.1 0 2.9z\"/>",
    "attrs": {
      "viewBox": "0 0 28.8 28.8",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});