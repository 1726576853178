define("ember-svg-jar/inlined/statement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M28 0H4C1.8 0 0 1.8 0 4v24c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4V4c0-2.2-1.8-4-4-4zm-6 30H4c-1.1 0-2-.9-2-2V8h20zm8-2c0 1.1-.9 2-2 2h-4V8h6zm0-22H2V4c0-1.1.9-2 2-2h24c1.1 0 2 .9 2 2zM7 20h10c.6 0 1-.4 1-1s-.4-1-1-1H7c-.6 0-1 .4-1 1s.4 1 1 1zm0-6h10c.6 0 1-.4 1-1s-.4-1-1-1H7c-.6 0-1 .4-1 1s.4 1 1 1zm0 12h10c.6 0 1-.4 1-1s-.4-1-1-1H7c-.6 0-1 .4-1 1s.4 1 1 1z\" fill=\"#3c4042\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});