define("ember-svg-jar/inlined/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M22.7 8.5c-.3-.3-.8-.3-1.2 0l-6 6-6-6c-.3-.3-.8-.3-1.2 0-.3.3-.3.8 0 1.2l6 6-6 6c-.3.3-.3.8 0 1.2.4 0 .6.1.8.1s.4-.1.6-.2l6-6 6 6c.2.2.4.2.6.2s.4-.1.6-.2c.3-.3.3-.8 0-1.2l-6-6 6-6c.2-.2.2-.8-.2-1.1z\"/><path d=\"M15.6.1C7.1.1.1 7.1.1 15.6s7 15.5 15.5 15.5 15.5-7 15.5-15.5S24.2.1 15.6.1zm0 29.3C8 29.4 1.9 23.2 1.9 15.6S8 1.9 15.6 1.9 29.4 8 29.4 15.6s-6.2 13.8-13.8 13.8z\"/>",
    "attrs": {
      "viewBox": "0 0 31.3 31.3",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});