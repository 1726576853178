define("ember-svg-jar/inlined/explore", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.5 34.7H4L9.1 9.2h8.4zM24 34.7h13.5L32.4 9.2H24zM12.8 21h16.6M5.3 28.3h12.2M24.4 28.3h11.8M17.5 9.2V4.9h-6.1v4.3M30.1 9.2V4.9H24v4.3\"/>",
    "attrs": {
      "viewBox": "0 0 41 40",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});