define("ember-svg-jar/inlined/star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M32 12l-11-1-5-11-5 11-11 1 8.3 8.4L6 32l10-6.1L26 32l-2.2-11.6zM17 24.2l-1-.6-1 .6-6.1 3.7 1.4-7.2.2-1-.8-.7-5.3-5.4 6.8-.6 1.2-.1.5-1.1 3.2-7 3.2 7 .5 1.1 1.2.1 6.8.6-5.5 5.4-.7.7.2 1 1.4 7.2z\" fill=\"#3c4042\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});