define("ember-svg-jar/inlined/tick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15.6.3l-9.8 9.8-4.5-4.6C1 5.2.5 5.2.2 5.5s-.3.8 0 1.1l5 5.1c.1.1.3.2.5.2.1 0 .2 0 .3-.1.1 0 .2-.1.3-.2L16.6 1.3c.3-.3.3-.8 0-1.1s-.7-.2-1 .1z\" fill=\"#010101\"/>",
    "attrs": {
      "viewBox": "0 0 16.9 11.9",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});