define("ember-svg-jar/inlined/trash-can", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M20.6 20.6h-2.9v20.6h2.9zm-7.4 0h-2.9v20.6h2.9zM35.3 5.9h-8.8V1.5C26.5.7 25.8 0 25 0H13.2c-.8 0-1.5.7-1.5 1.5v4.4H2.9C1.3 5.9 0 7.2 0 8.8v2.9c0 1.6 1.3 2.9 2.9 2.9V44c0 1.6 1.3 2.9 2.9 2.9h26.4c1.6 0 2.9-1.3 2.9-2.9V14.7c1.6 0 2.9-1.3 2.9-2.9V8.9c.2-1.7-1.1-3-2.7-3zm-20.6-3h8.8v2.9h-8.8zm17.6 39.7c0 .8-.7 1.5-1.5 1.5H7.4c-.8 0-1.5-.7-1.5-1.5V14.7h26.4zm1.5-30.9H4.4c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h29.4c.8 0 1.5.7 1.5 1.5 0 .9-.7 1.5-1.5 1.5zm-5.9 8.9H25v20.6h2.9z\" fill=\"#3c4042\"/>",
    "attrs": {
      "viewBox": "0 0 38 46.9",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});