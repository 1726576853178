define("ember-svg-jar/inlined/team", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.76 22.01v-.26-3.88c0-1.4.46-2.63 1.44-3.64.88-.91 1.96-1.49 3.24-1.53 1.49-.05 2.99-.05 4.48.01.9.04 1.72.43 2.44.99 1 .77 1.64 1.77 1.91 2.99.08.35.1.72.11 1.08.01 1.31 0 2.63.01 3.94 0 .16-.04.24-.19.31-1.1.52-2.28.8-3.47.98-1.3.2-2.61.25-3.93.17-1.41-.09-2.81-.3-4.18-.65-.61-.16-1.22-.34-1.86-.51z\"/><path d=\"M9.64 8.55c.07 1.32.57 2.45 1.5 3.38-.4.18-.78.34-1.14.53-1.12.6-1.97 1.49-2.54 2.63-.47.92-.66 1.91-.65 2.94v1c-1.33-.07-2.62-.26-3.88-.57-.66-.16-1.31-.35-1.97-.52-.16-.04-.21-.12-.21-.29.01-1.36 0-2.73.01-4.09C.78 11.3 2.33 9.3 4.5 8.73c.48-.13 1-.19 1.5-.2 1.05-.03 2.1-.01 3.16-.01.14 0 .3.02.48.03zM18.04 11.97c.23-.3.46-.57.66-.86.5-.72.75-1.53.82-2.4.01-.16.07-.19.21-.19 1.05 0 2.11-.01 3.16.01 1.68.03 3.06.7 4.1 2.03.54.7.87 1.5.99 2.38.03.23.05.46.05.69 0 1.33 0 2.66.01 3.99 0 .14-.04.22-.17.28-.92.44-1.89.73-2.9.89-.78.13-1.57.19-2.35.27-.07.01-.14 0-.25 0v-.29c0-.78.02-1.57-.16-2.34-.22-.93-.63-1.77-1.25-2.51-.75-.9-1.69-1.53-2.8-1.91-.05-.01-.08-.03-.12-.04zM18.58 8.37c.02 2.2-1.75 3.99-3.95 4.02-2.24.02-4.05-1.79-4.05-3.99 0-2.21 1.76-4.01 3.97-4.02 2.23 0 4.02 1.75 4.03 3.99zM3.57 4.41C3.56 2.24 5.04.55 7.05.28c1.97-.27 3.86.99 4.37 2.92.09.35.11.73.14 1.09.01.1-.05.23-.13.3-.9.76-1.45 1.72-1.69 2.86-.03.15-.1.22-.23.29-2.06 1.15-4.65.3-5.61-1.86-.23-.51-.35-1.06-.33-1.47zM25.21 4.11c-.05 1.63-.67 2.8-1.96 3.58-1.13.68-2.32.74-3.54.28-.13-.05-.21-.12-.23-.27-.15-1.08-.63-2.01-1.4-2.78-.22-.22-.48-.39-.71-.6-.07-.06-.14-.17-.14-.26.02-.99.41-1.85 1.08-2.56.77-.82 1.75-1.24 2.87-1.24 1.82 0 3.38 1.16 3.87 2.92.09.34.12.7.16.93z\"/>",
    "attrs": {
      "viewBox": "0 0 28.8 24.15",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});