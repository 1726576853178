define("ember-svg-jar/inlined/powerbi-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#fff\"><path d=\"M11.1 27.3c0 1.1-.9 2.1-2.1 2.1s-2.1-.9-2.1-2.1v-9c0-1.1.9-2.1 2.1-2.1s2.1.9 2.1 2.1zM17.1 29.7c0 1.1-.9 2.1-2.1 2.1s-2-.9-2-2.1V16c0-1.1.9-2.1 2.1-2.1s2.1.9 2.1 2.1v13.7zM5 25.3c0 1.1-.9 2.1-2.1 2.1S.8 26.5.8 25.3v-4.9c0-1.1.9-2.1 2.1-2.1s2.1 1 2.1 2.1zM23.1 31.9c0 1.1-.9 2.1-2.1 2.1-1.1 0-2-1-2-2.1V13.7c0-1.1.9-2.1 2.1-2.1 1.1 0 2.1.9 2.1 2.1v18.2z\"/><path d=\"M29.7 31c-.4 0-.7-.1-1.1-.2l-2.5-.8c-.4-.1-.7-.6-.5-1 .1-.4.6-.7 1-.5l2.6.8c.5.2 1.1.1 1.6-.3.5-.3.7-.9.7-1.4V10.9c0-.8-.5-1.5-1.2-1.7L5.8 1.8c-.5-.2-1.1-.1-1.6.2-.4.4-.7.9-.7 1.5V15c0 .5-.4.8-.8.8-.5 0-.8-.4-.8-.8V3.5c0-1.1.5-2.1 1.4-2.7.9-.7 2-.8 3-.5l24.4 7.4c1.4.4 2.4 1.8 2.4 3.3v16.7c0 1.1-.5 2.1-1.4 2.8-.6.2-1.3.5-2 .5\"/></g>",
    "attrs": {
      "viewBox": "0 0 34 34",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});