define("ember-svg-jar/inlined/close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M15.4 14L27.7 1.7c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L14 12.6 1.7.3C1.3-.1.7-.1.3.3c-.4.4-.4 1 0 1.4L12.6 14 .3 26.3c-.4.4-.4 1 0 1.4s1 .4 1.4 0L14 15.4l12.3 12.3c.4.4 1 .4 1.4 0s.4-1 0-1.4z\" fill=\"currentColor\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "viewBox": "0 0 28 28",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});