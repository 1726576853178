define("ember-svg-jar/inlined/true", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15.6.1C7.1.1.1 7.1.1 15.6s7 15.5 15.5 15.5 15.5-7 15.5-15.5S24.2.1 15.6.1z\" fill=\"#0fb706\"/><path d=\"M25.8 8.9L12.7 22l-6-6.2c-.4-.4-1.1-.4-1.5 0s-.4 1.1 0 1.5l6.7 6.8c.1.1.4.3.7.3.1 0 .3 0 .4-.1.1 0 .3-.1.4-.3l13.8-13.8c.4-.4.4-1.1 0-1.5-.4-.3-1-.2-1.4.2z\" fill=\"#fff\" stroke=\"#fff\" stroke-miterlimit=\"10\"/>",
    "attrs": {
      "viewBox": "0 0 31.3 31.3",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});